import { Box, Button, ButtonProps, Flex, Icon, Image, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure } from "@chakra-ui/react"
import Link from "next/link"
import { FC } from "react"
import { IconType } from "react-icons"
import { MenuChildren, Thumb } from "~/utils/Types/Home"

interface ButtonMenuProps extends ButtonProps {
	linkHref: string
	icon?: Thumb
	sizeIcon?: string
	text: string
	color?: string
	mode: 'mobile' | 'desktop'
	menusChildren: MenuChildren[]
	bgMenu?: string;
	colorMenu?: string;
}

export const ButtonMenuSubMainModal: FC<ButtonMenuProps> = ({
	icon,
	linkHref,
	sizeIcon,
	text,
	color,
	menusChildren,
	mode,
	bgMenu,
	colorMenu,
	...rest
}) => {
	const { isOpen, onClose, onOpen } = useDisclosure()
	return (
		<Menu
			isOpen={isOpen}
			onClose={onClose}
			placement="bottom"
		>
			<Box display="inline-block">
				<MenuButton
					as={Button}
					size="sm"
					fontSize="md"
					variant="ghost"
					color={color ? color : "black"}
					rounded="0"
					px={0}
					onClick={mode === 'mobile' ? onOpen : undefined}
					onMouseOver={mode === 'desktop' ? onOpen : undefined}
					borderBottom="2px"
					borderColor={"transparent"}
					_hover={{
						borderColor: "transparent",
					}}
					_active={{
						bg: "none"
					}}
					css={{
						'&>span': {
							display: 'flex',
							height: '100%',
							alignItems: "center"
						}
					}}
					{...rest}
				>
					{icon && <Image src={icon.url} alt={icon.alt_text ?? text} width="25px" height="25px" mr="2" />}
					<Text mb={icon ? "-0.50rem" : "-0.40rem"} >{text}</Text>
				</MenuButton>
			</Box>
			<MenuList
				border="none"
				bg={bgMenu}
				css={{
					'&>button:first-of-type': {
						boxShadow: "none",
					}
				}}
			>
				{menusChildren.map((menuChildrenKey) => (
					<MenuItem
						color={colorMenu}
						key={menuChildrenKey.secure_id}
						bg={bgMenu}
						as={Link}
						href={menuChildrenKey.url}
					>
						{menuChildrenKey.title}
					</MenuItem>
				))}
			</MenuList>
		</Menu>
	)
}
