type StringOrObjectQueryParamsOfAsPathUrlProps = {
	urlAsPath: string;
	newValues?: { [key: string]: string }[];
}

// Função feita para gerar a string dos query-params para o push pois o router.query não está funcionando corretamente.
export function StringOrObjectQueryParamsOfAsPathUrl({ urlAsPath, newValues }: StringOrObjectQueryParamsOfAsPathUrlProps) {
	const queryParams = urlAsPath.split('?').length > 1
		? new URLSearchParams(urlAsPath.split('?')[1])
		: undefined

	// Transformar em objeto
	const queryParamsObj: { [key: string]: string } = {}
	if (queryParams) {
		queryParams.forEach((value, key) => {
			queryParamsObj[key] = value;
		});
	}

	if (newValues?.length) {
		newValues.forEach((item) => {
			queryParamsObj[Object.keys(item)[0]] = Object.values(item)[0];
		});
	}

	const queryString = Object.entries(queryParamsObj)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join('&');

	return {
		queryString,
		queryParamsObj,
	}
}
