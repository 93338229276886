import {
	Checkbox,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
	useToast
} from "@chakra-ui/react"
import { useState } from "react"

import * as yup from 'yup'
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "@tanstack/react-query"
import { yupResolver } from "@hookform/resolvers/yup"
import { SubmitHandler, useForm } from "react-hook-form"

import { api } from "~/services/apiClient"
import { OfficeOptions } from "~/utils/OptionsOffice"

import { Input } from "~/components/global/Form/Input"
import { InputSelect } from "~/components/global/Form/InputSelect"
import { ButtonSubmit } from "~/components/global/Buttons/ButtonSubmit"
import { ReCaptchaVerification } from "~/components/global/ReCaptchaVerification"

type ModalNewsLetterPortal = {
	email?: string
	isOpen: boolean
	onClose: () => void
}

type FormData = {
	name: string
	email: string
	company: string
	function: string
	confirm: boolean
}

const FormSchema = yup.object().shape({
	email: yup.string()
		.required('E-mail obrigatório.')
		.typeError('E-mail obrigatório.'),
})

export function ModalNewsLetterPortal({ isOpen, onClose, email }: ModalNewsLetterPortal) {
	const toast = useToast()
	const { register, formState, formState: { errors }, handleSubmit } = useForm<FormData>({
		resolver: yupResolver(FormSchema),
		defaultValues: {
			email: email ?? ""
		}
	})

	const [isReset, setIsReset] = useState<boolean>(false)
	const [isVerified, setIsverified] = useState<boolean>(false)

	const add = useMutation(async (values: FormData) => {
		return await api.post('/v1/newsletter-lead', {
			...values,
		})
	}, {
		onSuccess: (response: AxiosResponse) => {
			toast({
				title: response.data?.message || 'Cadastro realizado com sucesso!',
				position: "top-right",
				status: "success",
				isClosable: true,
			})
			onClose()
			setIsReset(true)
			setIsverified(false)
		},
		onError: (error: AxiosError<any>) => {
			toast({
				title: error?.response?.data?.message || 'Ocorreu um problema ao realizar o cadastro.',
				position: "top-right",
				status: error?.response?.data?.type || "error",
				isClosable: true,
			})
		}
	})

	const handleAdd: SubmitHandler<FormData> = async (values) => {
		try {
			await add.mutateAsync(values)
		} catch { }
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="xl" >
			<ModalOverlay />
			<ModalContent padding="4">
				<ModalHeader textTransform="uppercase">NEWSLETTER</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<VStack
						spacing="4"
						as="form"
						onSubmit={handleSubmit(handleAdd)}
					>
						<Input
							label="Nome"
							type="text"
							placeholder="Digite seu nome"
							{...register('name')}
						/>
						<Input
							label="E-mail"
							type="email"
							placeholder="Digite seu e-mail"
							error={errors.email}
							{...register('email')}
						/>
						<Input
							label="Empresa"
							type="text"
							placeholder="Digite sua empresa"
							{...register('company')}
						/>
						<InputSelect
							label="Cargo"
							options={OfficeOptions}
							placeholder="Escolha uma função"
							{...register('function')}
						/>
						<Text
							fontWeight="black"
						>
							Para saber mais sobre como usamos seus dados, acesse nossa&nbsp;
							<Link color="#337ab7" isExternal href={`${process.env.NEXT_PUBLIC_HOST_URL}/aviso-de-privacidade`}>
								política de privacidade
							</Link>.
						</Text>
						<Checkbox
							defaultChecked
							{...register('confirm', { disabled: true })}
							fontWeight="black"
						>
							Desejo receber comunicações da SA+ com novidades relevantes sobre o setor.
						</Checkbox>
						<ReCaptchaVerification
							setIsverified={setIsverified}
							isReset={isReset}
							setIsReset={setIsReset}
						/>
						<ButtonSubmit w="min-content" alignSelf="flex-end" isLoading={formState.isSubmitting} isDisabled={formState.isSubmitting || !isVerified}>
							Salvar
						</ButtonSubmit>
					</VStack>
				</ModalBody>
				{/* <ModalFooter alignItems="flex-end">
					<Box>
						<ButtonSubmit px="4" isLoading={formState.isSubmitting} isDisabled={formState.isSubmitting}>
							Salvar
						</ButtonSubmit>
					</Box>
				</ModalFooter> */}
			</ModalContent>
		</Modal>
	)
}
