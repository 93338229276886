import { Button, ButtonProps, Icon, Text } from "@chakra-ui/react"
import Link from "next/link"
import { FC } from "react"
import { IconType } from "react-icons"
import { FaEnvelope } from "react-icons/fa"

interface ButtonIconOutlineProps extends ButtonProps {
	linkHref: string
	icon?: IconType
	sizeIcon?: string
	text?: string
	target?: string
}

export const ButtonIconOutline: FC<ButtonIconOutlineProps> = ({ icon, linkHref, sizeIcon, text, ...rest }) => {
	return (
		<Button
			as={Link}
			href={linkHref}
			passHref
			size="sm"
			fontSize="sm"
			alignItems="center"
			variant="outline"
			color="white"
			p={!text ? "5" : "6"}
			w={!text ? "5" : undefined}
			h={!text ? "5" : undefined}
			rounded={text ? "3xl" : "full"}
			_hover={{
				bg: "#fff",
				color: "#7d4f62"
			}}
			{...rest}
		>
			<Icon as={!!icon ? icon : FaEnvelope} fontSize={!!sizeIcon ? sizeIcon : "lg"} />
			{text &&
				<Text mb="-0.188rem" ml="2">{text}</Text>
			}
		</Button>
	)
}
