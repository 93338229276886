import { Dispatch, FC, SetStateAction, useEffect, useRef } from "react"

import ReCAPTCHA from "react-google-recaptcha"

// import { verifyCaptcha } from "~/utils/Functions/ServerActions"

interface ReCaptchaVerificationProps {
	isReset: boolean
	setIsReset: Dispatch<SetStateAction<boolean>>
	setIsverified: Dispatch<SetStateAction<boolean>>
}

export const ReCaptchaVerification: FC<ReCaptchaVerificationProps> = ({ setIsverified, isReset, setIsReset }) => {
	const recaptchaRef = useRef<ReCAPTCHA>(null)

	// async function handleCaptchaSubmission(token: string | null) {
	// 	// Server function to verify captcha
	// 	await verifyCaptcha(token)
	// 		.then(() => setIsverified(true))
	// 		.catch(() => setIsverified(false))
	// }

	useEffect(() => {
		if (isReset) {
			setIsReset(false)
			recaptchaRef.current?.reset()
		}
	}, [isReset])


	return (
		//@ts-ignore
		<ReCAPTCHA
			sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
			ref={recaptchaRef}
			onChange={(e) => setIsverified(true)}
			onExpired={() => setIsverified(false)}
		/>
	)
}
