export const OfficeOptions = [
	{
		label: 'Acionista',
		value: 'Acionista'
	},
	{
		label: 'Administrador',
		value: 'Administrador(a)'
	},
	{
		label: 'Analista',
		value: 'Analista'
	},
	{
		label: 'Assessor(a)',
		value: 'Assessor(a)'
	},
	{
		label: 'Assistente',
		value: 'Assistente'
	},
	{
		label: 'Auxiliar',
		value: 'Auxiliar'
	},
	{
		label: 'Chefe',
		value: 'Chefe'
	},
	{
		label: 'Comprador(a)',
		value: 'Comprador(a)'
	},
	{
		label: 'Consultor(a)',
		value: 'Consultor(a)'
	},
	{
		label: 'Coordenador(a)',
		value: 'Coordenador(a)'
	},
	{
		label: 'Diretor(a)',
		value: 'Diretor(a)'
	},
	{
		label: 'Encarregado(a)',
		value: 'Encarregado(a)'
	},
	{
		label: 'Gerente',
		value: 'Gerente'
	},
	{
		label: 'Outros',
		value: 'Outros'
	},
	{
		label: 'Presidente',
		value: 'Presidente'
	},
	{
		label: 'Proprietário(a)',
		value: 'Proprietário(a)'
	},
	{
		label: 'Representante',
		value: 'Representante'
	},
	{
		label: 'Secretária',
		value: 'Secretária'
	},
	{
		label: 'Sócio(a)',
		value: 'Sócio(a)',
	},
	{
		label: 'Superintendente',
		value: 'Superintendente',
	},
	{
		label: 'Supervisor(a)',
		value: 'Supervisor(a)',
	},
	{
		label: 'Vendedor(a)',
		value: 'Vendedor(a)',
	},
	{
		label: 'Vice Presidente',
		value: 'Vice Presidente',
	},
]
