import {
	Input as ChakraInput,
	InputGroupProps as ChakraInputProps,
	FormControl,
	FormErrorMessage,
	FormLabel, HStack, Icon, IconButton,
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { FieldError } from 'react-hook-form'

import { forwardRef, ForwardRefRenderFunction, MutableRefObject, useEffect, useRef, useState } from 'react'
import { RiArrowRightFill, RiSearchLine } from 'react-icons/ri'
import { useDebouncedPromise } from '~/utils/Functions/useDebouncedPromise'
import { StringOrObjectQueryParamsOfAsPathUrl } from '~/utils/Functions/StringOrObjectQueryParamsOfAsPathUrl'

interface InputProps extends ChakraInputProps {
	name: string
	label?: string
	placeholder?: string
	error?: FieldError
	arm?: string
	isHome?: boolean;
	background?: string;
}

const InputIconBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({
	name,
	label,
	arm,
	error = null,
	isHome = false,
	background,
	placeholder,
	...rest
}, ref
) => {
	const router = useRouter()
	const [search, setSearch] = useState(router.query.q)
	const [inputIsOpen, setInputIsOpen] = useState(!!isHome ? false : true)

	async function HandleSearch() {
		const { queryString } = StringOrObjectQueryParamsOfAsPathUrl({
			urlAsPath: router.asPath,
			newValues: search ? [
				{ q: search as string }
			] : undefined
		})

		router.push(`/${arm ? `${arm}/` : ""}buscar?${queryString}`)
	}

	const debouncedChange = useDebouncedPromise(HandleSearch, 1000)

	useEffect(() => {
		if (search) {
			debouncedChange()
		}
	}, [search])

	useEffect(() => {
		const { queryParamsObj } = StringOrObjectQueryParamsOfAsPathUrl({ urlAsPath: router.asPath })

		if (queryParamsObj['q']) {
			setSearch(queryParamsObj['q'])
		}
	}, [router.asPath])

	return (
		<FormControl isInvalid={!!error}>
			{!!label &&
				<FormLabel
					w="100%"
					htmlFor={name}
					display="flex"
					alignItems="center"
					fontWeight="bold"
				>
					{label}
				</FormLabel>
			}
			<HStack
				justify={inputIsOpen ? "space-between" : "center"}
				align="center"
				rounded="full"
				bg={isHome ? "white" : background}
				height="48px"
				paddingX={inputIsOpen ? 4 : 0}
				onMouseEnter={() => {
					setInputIsOpen(true)
				}}
				onMouseLeave={() => {
					const inputElement = document.getElementById(name);
					if (!(inputElement && document.activeElement === inputElement)) {
						return (!!isHome) ? setInputIsOpen(false) : undefined
					}
				}}
				border="2px solid #EDF2F7"
				w={inputIsOpen ? undefined : "48px"}
				spacing={inputIsOpen ? 2 : 0}
			>
				<Icon
					as={RiSearchLine}
					fontSize="lg"
					color="black"
				/>
				{inputIsOpen && (
					<>
						<ChakraInput
							fontSize={{ sm: 'md', md: 'lg', xl: 'sm', '2xl': 'lg' }}
							id={name}
							name={name}
							size="lg"
							variant="unstyled"
							ref={ref}
							defaultValue={search}
							onChange={e => setSearch(e.target.value)}
							bg="transparent"
							placeholder={placeholder}
							height="100%"
							minW="30px"
							onBlur={() => (!!isHome) ? setInputIsOpen(false) : undefined}
						/>
						<IconButton
							size='xs'
							aria-label='Icon Right'
							colorScheme="pink"
							rounded="full"
							icon={<Icon as={RiArrowRightFill} fontSize="xl" />}
							isDisabled={!search}
							onClick={() => {
								// Função feita para gerar o objeto query de router.query que não está funcionando corretamente.
								const { queryString } = StringOrObjectQueryParamsOfAsPathUrl({
									urlAsPath: router.asPath,
									newValues: search ? [
										{ q: search as string }
									] : undefined
								})

								router.push(`/buscar?${queryString}`)
							}}
						/>
					</>
				)}
			</HStack>
			{error &&
				<FormErrorMessage fontSize="xs">{error.message}</FormErrorMessage>
			}
		</FormControl>
	)
}

export const InputIcon = forwardRef(InputIconBase)
