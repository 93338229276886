import {
	Flex,
	Grid,
	GridItem,
	HStack,
	Image,
	Text,
	useBreakpointValue,
} from "@chakra-ui/react";
import { MenuFooter, SocialMediaHomeProps, Thumb } from "~/utils/Types/Home";
import Link from "next/link";
import { LinkIconImageOutline } from "../Links/LinkIconImageOutline";
import { useMemo } from "react";

interface FooterProps {
	customLogo?: Thumb
	customBackgroud?: string
	footer_menu_right: MenuFooter[];
	footer_menu_left: MenuFooter[];
	socialMedias: SocialMediaHomeProps[];
}

export function Footer({
	footer_menu_left,
	footer_menu_right,
	socialMedias,
	customBackgroud,
	customLogo
}: FooterProps) {
	const isWideVersion2xl = useBreakpointValue({
		base: false,
		"2xl": true,
	});
	const isWideVersionXl = useBreakpointValue({
		base: false,
		xl: true,
	});
	const isWideVersionLg = useBreakpointValue({
		base: false,
		lg: true,
	});

	const gapping = useMemo(() => {
		if (isWideVersion2xl) {
			return "28";
		} else if (isWideVersionXl) {
			return "26";
		} else if (isWideVersionLg) {
			return "10";
		} else {
			return "4";
		}
	}, [isWideVersion2xl, isWideVersionXl, isWideVersionLg]);
	return (
		<Flex
			as="footer"
			w="100%"
			minH="26.25rem"
			bg={customBackgroud ? customBackgroud : "backgroundHeader"}
			direction="column"
			position="absolute"
			alignItems="center"
			p="4"
			gap={isWideVersionLg ? undefined : "4"}
		>
			<Grid
				w="100%"
				maxW="1326px"
				paddingTop="16"
				templateColumns={isWideVersionLg ? "repeat(12, 1fr)" : "1fr"}
				gap={gapping}
			>
				<GridItem colSpan={isWideVersionLg ? 6 : 5}>
					<Link href="/">
						<Image
							height="110px"
							width="336px"
							fit="contain"
							src={customLogo ? customLogo.url : "/logoSAplus.png"}
							alt={customLogo?.alt_text ?? "Logo SA"}
						/>
					</Link>
					<Text
						marginTop="4"
						as="a"
						color="#FFF"
						textStyle="textSM"
						href="mailto:CONTATO@SAMAISVAREJO.COM.BR"
					>
						CONTATO@SAMAISVAREJO.COM.BR
					</Text>
				</GridItem>
				<GridItem colSpan={3}>
					{footer_menu_left.map((menuLeft) => (
						<Link
							key={menuLeft.secure_id}
							href={menuLeft.url}
							passHref
							prefetch={false}
						>
							<Text
								textStyle="textSM"
								fontWeight="medium"
								color="#FFF"
								textTransform="uppercase"
							>
								{menuLeft.title}
							</Text>
						</Link>
					))}
				</GridItem>
				<GridItem colSpan={3}>
					{footer_menu_right.map((menuRight) => (
						<Link
							key={menuRight.secure_id}
							href={menuRight.url}
							passHref
							prefetch={false}
						>
							<Text
								textStyle="textSM"
								fontWeight="medium"
								color="#FFF"
								textTransform="uppercase"
							>
								{menuRight.title}
							</Text>
						</Link>
					))}
				</GridItem>
			</Grid>
			{/* <Flex
				paddingTop="16"
				gap={isWideVersion ? "28" : "4"}
				flexDirection={isWideVersion ? "row" : "column"}
			>
				<Flex justify="flex-start" flexDir="column">
					<Link href="/">
						<Image height="110px" width="336px" fit="cover" src="/logoSAplus.png" alt="" />
					</Link>
					<Text
						marginTop="4"
						as="a"
						color="#FFF"
						textStyle="textSM"
						href="mailto:CONTATO@SAMAISVAREJO.COM.BR"
					>
						CONTATO@SAMAISVAREJO.COM.BR
					</Text>
				</Flex>
				<VStack align="flex-start" spacing={1}>
					{footer_menu_left.map((menuLeft) => (
						<Link
							key={menuLeft.secure_id}
							href={menuLeft.url}
							passHref
							prefetch={false}
						>
							<Text
								textStyle="textSM"
								fontWeight="medium"
								color="#FFF"
								textTransform="uppercase"
							>
								{menuLeft.title}
							</Text>
						</Link>
					))}
				</VStack>

				<VStack align={isWideVersion ? "center" : "flex-start"}>
					<VStack align="flex-start" spacing={1}>
						{footer_menu_right.map((menuRight) => (
							<Link
								key={menuRight.secure_id}
								href={menuRight.url}
								passHref
								prefetch={false}
							>
								<Text
									textStyle="textSM"
									fontWeight="medium"
									color="#FFF"
									textTransform="uppercase"
								>
									{menuRight.title}
								</Text>
							</Link>
						))}
					</VStack>
				</VStack>
			</Flex> */}
			<HStack spacing={1} maxW="1326px" width="100%" justify="flex-start">
				{socialMedias?.map((socialMedia) => (
					<LinkIconImageOutline
						key={socialMedia.secure_id}
						isExternal
						linkHref={socialMedia.link}
						iconSrc={socialMedia.icon.url}
						altIcon={socialMedia.icon.alt_text ?? socialMedia.name}
						height="48px"
						size="lg"
						variant="solid"
						bg={socialMedia.background}
					/>
				))}
			</HStack>
			<HStack
				position={isWideVersionLg ? "absolute" : "initial"}
				bottom="14"
				right="10"
				spacing="10"
			>
				<Image src="/cinva.png" alt="Logo Cinva" height="95px" />
			</HStack>
			<Flex marginTop={isWideVersionLg ? "14" : "8"}>
				<Text color="#FFF" fontWeight="medium">
					&copy; Somos uma marca do CINVA (CENTRO DE INTELIGENCIA E NEGOCIOS DO VAREJO - CINVA LTDA).
					© 2023 SA+ Ecossistema de Varejo. Todos os direitos Reservados
				</Text>
			</Flex>
		</Flex>
	);
}
