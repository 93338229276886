import { Button, ButtonProps, HStack, Image, Text } from "@chakra-ui/react"
import Link from "next/link"
import { useRouter } from "next/router"
import { FC, useMemo } from "react"
import { Thumb } from "~/utils/Types/Home"

interface ButtonMenuProps extends ButtonProps {
	linkHref: string
	icon?: Thumb
	sizeIcon?: string
	text: string
	color?: string
	backgroundActive?: string
	customBorderColor?: string
	shouldMatchExactHref?: boolean
}

export const ButtonMenu: FC<ButtonMenuProps> = ({ icon, linkHref, sizeIcon, text, color, backgroundActive = "#4f0d28", customBorderColor, shouldMatchExactHref = false, ...rest }) => {
	const router = useRouter()

	const isActive = useMemo(() => {
		if (shouldMatchExactHref && router.asPath === linkHref || router.asPath === rest.as) {
			return true
		}

		return !shouldMatchExactHref && (router.asPath.startsWith(String(linkHref)) || router.asPath.startsWith(String(rest.as)))
	}, [router.asPath])

	return (
		<Button
			// w="100%"
			h={customBorderColor ? "100%" : undefined}
			as={Link}
			href={linkHref}
			passHref
			size="sm"
			fontSize="md"
			variant="ghost"
			color={color ? color : "black"}
			rounded="0"
			px={customBorderColor ? "8" : 0}
			borderBottomWidth={customBorderColor ? customBorderColor === backgroundActive && !isActive ? "2px" : "16px" : "2px"}
			borderColor={{ sm: isActive ? "#de0045" : "transparent", lg: customBorderColor ? customBorderColor : isActive ? "#de0045" : "transparent" }}
			bg={customBorderColor && isActive ? customBorderColor : "inherit"}
			transform={customBorderColor ? "skew(-25deg)" : "none"}
			_hover={{
				bg: customBorderColor,
				borderColor: customBorderColor ? customBorderColor === backgroundActive && !isActive ? "#de0045" : customBorderColor : "#de0045",
			}}
			{...rest}
		>
			<HStack transform={customBorderColor ? "skew(25deg)" : "none"}>
				{icon && <Image src={icon.url} alt={icon.alt_text ?? text} width="16px" height="16px" />}
				{/* {icon && <Icon as={icon} fontSize={!!sizeIcon ? sizeIcon : "lg"} mr="2" />} */}
				<Text
					as="h2"
					fontWeight="bold"
					fontSize="md"
					mb={icon ? "-0.50rem" : "-0.40rem"}
				>{text}</Text>
			</HStack>
		</Button>
	)
}
