import { Button, ButtonProps, Icon, Link, Text } from "@chakra-ui/react"
import Image from "next/image"
import { FC } from "react"
import { FaEnvelope } from "react-icons/fa"

interface LinkIconImageOutlineProps extends ButtonProps {
	linkHref?: string
	iconSrc?: string
	sizeIcon?: string
	altIcon?: string
	text?: string
	isExternal: boolean
}

export const LinkIconImageOutline: FC<LinkIconImageOutlineProps> = ({ iconSrc, linkHref, sizeIcon, text, isExternal, altIcon, ...rest }) => {
	return (
		<Button
			as={Link}
			isExternal={isExternal}
			href={linkHref}
			size="sm"
			fontSize="sm"
			alignItems="center"
			variant="outline"
			color="white"
			p={!text ? "5" : "6"}
			w={!text ? "5" : undefined}
			h={!text ? "5" : undefined}
			rounded={text ? "3xl" : "full"}
			_hover={{
				bg: "#fff",
				color: "#7d4f62"
			}}
			{...rest}
		>
			{iconSrc ? (
				<Image
					src={iconSrc}
					alt={altIcon ? altIcon : 'Rede social'}
					width={25}
					height={25}
					style={{
						maxWidth: '25px',
						maxHeight: '25px',
						objectFit: 'contain',
					}}
				/>
			) : (
				<Icon as={FaEnvelope} fontSize={!!sizeIcon ? sizeIcon : "lg"} />
			)}
			{text &&
				<Text mb="-0.188rem" ml="2">{text}</Text>
			}
		</Button>
	)
}
