import { Box, Button, ButtonProps, Flex, Image, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure } from "@chakra-ui/react"
import Link from "next/link"
import { FC } from "react"
import { MenuChildren, Thumb } from "~/utils/Types/Home"

interface ButtonMenuProps extends ButtonProps {
	linkHref: string
	icon?: Thumb
	sizeIcon?: string
	text: string
	color?: string
	mode: 'mobile' | 'desktop'
	menusChildren: MenuChildren[]
}

export const ButtonMenuModal: FC<ButtonMenuProps> = ({
	icon,
	linkHref,
	sizeIcon,
	text,
	color,
	menusChildren,
	mode,
	...rest
}) => {
	const { isOpen, onClose, onOpen } = useDisclosure()

	return (
		<Box>
			<Menu
				isOpen={isOpen}
				onClose={onClose}
				placement="bottom"
			>
				<Box display="inline-block" bg="backgroundHeader">
					<MenuButton
						as={Button}
						size="sm"
						fontSize="md"
						variant="ghost"
						color={color ? color : "black"}
						rounded="0"
						px={0}
						onClick={mode === 'mobile' ? onOpen : undefined}
						onMouseOver={mode === 'desktop' ? onOpen : undefined}
						borderBottom="2px"
						borderColor={"transparent"}
						_hover={{
							borderColor: "transparent",
						}}
						_active={{
							bg: "none"
						}}
						{...rest}
					>
						<Flex>
							{icon && <Image src={icon.url} alt={icon.alt_text ?? text} width="16px" height="16px" mr="2" />}
							{/* {icon && <Icon as={icon} fontSize={!!sizeIcon ? sizeIcon : "lg"} mr="2" />} */}
							<Text mb={icon ? "-0.50rem" : "-0.40rem"} >{text}</Text>
						</Flex>
					</MenuButton>
				</Box>
				<MenuList
					bg="backgroundHeader"
					border="none"
					css={{
						'&>button:first-of-type': {
							boxShadow: "none",
						}
					}}
				>
					{menusChildren.map((menuChildrenKey) => (
						<MenuItem
							bg="backgroundHeader"
							color="#FFF"
							key={menuChildrenKey.secure_id}
							as={Link}
							href={menuChildrenKey.url}
							margin="none"
						>
							{menuChildrenKey.icon && <Image src={menuChildrenKey.icon.url} alt={menuChildrenKey.icon.alt_text ?? menuChildrenKey.title} width="16px" height="16px" mr="2" />}
							{menuChildrenKey.title}
						</MenuItem>
					))}
				</MenuList>
			</Menu>
		</Box>
	)
}
