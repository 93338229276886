import {
	Box,
	Button,
	Divider,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerOverlay,
	Flex,
	Grid,
	HStack,
	Icon,
	ListItem,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	UnorderedList,
	useBreakpointValue,
	useDisclosure,
	useMediaQuery,
	VStack
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useRouter } from "next/router";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { CgMenu } from "react-icons/cg";
import { FaRegClock } from "react-icons/fa";
import { RiSunCloudyFill } from "react-icons/ri";
import { api } from "~/services/apiClient";
import {
	MenuMain,
	PostHomeProps,
	SocialMediaHomeProps,
	Thumb
} from "~/utils/Types/Home";
import { ButtonIconOutline } from "../Buttons/ButtonIconOutline";
import { ButtonMenu } from "../Buttons/ButtonMenu";
import { ButtonMenuModal } from "../Buttons/ButtonMenuModal";
import { ButtonMenuSubMainModal } from "../Buttons/ButtonMenuSubMainModal";

import ImageNextjs from "next/image";
import Link from "next/link";
import { BsPersonCircle } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { TbArrowBackUp } from "react-icons/tb";
import { useAuthContext } from "~/contexts/AuthContext";
import FormatPortalLinks from "~/utils/Functions/FormatPortalLinks";
import { ModalNewsLetterPortal } from "../ModalNewsLetterPortal";
import { InputIcon } from "../SidebarInner/InputIcon";

type HeaderProps = {
	customLogo?: Thumb;
	customBackgroud?: string;
	news?: PostHomeProps[];
	menuMain: MenuMain[];
	subMain: MenuMain[];
	socialMedias?: SocialMediaHomeProps[];
	submenuVisibility?: 'hidden' | 'visible' | 'backToHome';
	pageTitle?: string;
};

export const HeaderPortal = ({
	news,
	menuMain,
	subMain,
	// socialMedias,
	customBackgroud,
	customLogo,
	submenuVisibility = 'visible',
	pageTitle,
}: HeaderProps) => {
	const isWideVersion = useBreakpointValue({
		base: false,
		xl: true,
	});
	const [isLargerThan1355] = useMediaQuery("(min-width: 1355px)");

	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: accountPopOver,
		onToggle,
		onClose: onCloseAccountPopoOver,
	} = useDisclosure();
	const btnRef = useRef(null);
	const { push, asPath } = useRouter();
	const { user, isAuthenticated, settings } = useAuthContext();

	const [currentTemperature, setCurrentTemperature] = useState<number>();
	const [bannerNumber, setBannerNumber] = useState(0);
	const [timeId, setTimeId] = useState<NodeJS.Timeout | null>(null);

	const [newsletterModal, setNewsletterModal] = useState(false);

	const titlePage = useMemo(() => {
		switch (asPath) {
			case '/solucaosortimento':
				return settings?.title_solucao_sortimento
			case '/noticias':
				return settings?.title_posts
			case '/prateleiras':
				return settings?.title_prateleira
			case '/revistas':
				return settings?.title_magazine
			case '/':
				return settings?.head_name
			default:
				return pageTitle
		}
	}, [asPath])

	const handleCloeseNewsletterModal = () => setNewsletterModal(false);
	const handleOpenNewsletterModal = () => {
		setNewsletterModal(true)
		onClose()
	}

	const handleNextSlide = () => {
		if (!!news) {
			const totalBanners = news.length;
			if (bannerNumber < totalBanners - 1) {
				setBannerNumber(bannerNumber + 1);
			} else {
				setBannerNumber(0);
			}
		}
	};

	const startTime = () => {
		if (timeId) {
			clearTime();
			const id = setTimeout(handleNextSlide, 30000);
			setTimeId(() => id);
		} else {
			const id = setTimeout(handleNextSlide, 30000);
			setTimeId(() => id);
		}
	};

	const clearTime = () => {
		if (timeId) {
			clearTimeout(timeId);
		}
	};

	useEffect(() => {
		if (!!news) {
			startTime();
		}
	}, [news, bannerNumber]);

	useEffect(() => {
		if (!!settings?.azure_maps) {
			navigator.geolocation.getCurrentPosition(async (position) => {
				try {
					const { data } = await api.get("/v1/public/geolocation", {
						params: {
							latitude: position.coords.latitude,
							longitude: position.coords.longitude,
						},
					});

					if (data.currentTemperature)
						setCurrentTemperature(Math.ceil(data.currentTemperature));
				} catch { }
			});
		}
	}, []);

	return (
		<Flex
			as="header"
			w="100%"
			h={isWideVersion ? (isLargerThan1355 ? "21.25rem" : "26.25rem") : "auto"}
			bg={customBackgroud ? customBackgroud : "backgroundHeader"}
			direction="column"
			alignItems="center"
		>
			{!isWideVersion && (
				<>
					<Flex
						width="100%"
						justify="space-between"
						height="100%"
						paddingLeft="6"
						paddingRight="6"
						paddingBottom="3.5"
						paddingTop="3.5"
					>
						<Link href="/">
							<h1>
								{titlePage && (
									<title>{titlePage}</title>
								)}
								<ImageNextjs
									style={{
										objectFit: 'contain',
									}}
									height={48}
									width={250}
									src={customLogo ? customLogo.url : "/logoSAplus.png"}
									alt={customLogo?.alt_text ?? "SA+ Ecossistema de Varejo"}
								/>
							</h1>
						</Link>
						<Box
							title="Menu"
							as="button"
							display="flex"
							alignItems="center"
							justifyContent="center"
							ref={btnRef}
							onClick={onOpen}
						>
							<Icon as={CgMenu} fontSize="3xl" color="#FFF" />
						</Box>
					</Flex>
					<VStack>
						<Drawer
							isOpen={isOpen}
							placement="right"
							onClose={onClose}
							finalFocusRef={btnRef}
						>
							<DrawerOverlay />
							<DrawerContent
								bg={customBackgroud ? customBackgroud : "backgroundHeader"}
							>
								<Box width="100%" height="84px">
									<Box
										as="button"
										width="min-content"
										position="absolute"
										display="flex"
										alignItems="center"
										justifyContent="center"
										ref={btnRef}
										onClick={onClose}
										right="10"
										top="6"
									>
										<Icon as={CgMenu} fontSize="3xl" color="#de0045" />
									</Box>
								</Box>
								<DrawerBody>
									<VStack spacing="6">
										<InputIcon
											name="search"
											background="white"
											placeholder="Encontre na SA+"
										/>
										<ButtonIconOutline
											linkHref="#"
											text="NEWSLETTER"
											alignSelf="flex-end"
											onClick={handleOpenNewsletterModal}
										/>
										<Flex width="100%">
											<Text
												marginLeft="4"
												textTransform="uppercase"
												color="#FFF"
												fontWeight="extrabold"
											>
												Menu
											</Text>
										</Flex>
										<Flex width="100%">
											<Text
												as="a"
												href={isAuthenticated ? "/profile" : "/signin"}
												marginLeft="4"
												color="#FFF"
												fontWeight="extrabold"
											>
												{isAuthenticated ? "Sua conta" : "Entrar"}
											</Text>
										</Flex>
										{!isAuthenticated && (
											<Flex width="100%">
												<Text
													as="a"
													href="/signup"
													marginLeft="4"
													color="#FFF"
													fontWeight="extrabold"
												>
													Cadastrar
												</Text>
											</Flex>
										)}
										{menuMain.length ? (
											<Flex w="100%">
												<UnorderedList
													listStyleType="none"
													spacing="0.5"
													marginLeft="4"
												>
													{subMain.map((subMainItem) => (
														<Fragment key={subMainItem.secure_id}>
															{subMainItem.menusChildren.length ? (
																<ListItem display="flex" alignItems="center">
																	<Text fontSize="2xl" color="#FFF">
																		-
																	</Text>{" "}
																	&nbsp;
																	<ButtonMenuSubMainModal
																		mode="mobile"
																		bgMenu="backgroundHeader"
																		linkHref={subMainItem.url}
																		text={subMainItem.title}
																		color="#FFF"
																		colorMenu="#FFF"
																		menusChildren={subMain}
																	/>
																</ListItem>
															) : (
																<ListItem display="flex" alignItems="center">
																	<Text fontSize="2xl" color="#FFF">
																		-
																	</Text>{" "}
																	&nbsp;
																	<ButtonMenu
																		linkHref={subMainItem.url}
																		text={subMainItem.title}
																		color="#FFF"
																		shouldMatchExactHref={
																			subMainItem.url === "/"
																		}
																	/>
																</ListItem>
															)}
														</Fragment>
													))}
												</UnorderedList>
											</Flex>
										) : undefined}
										<Flex w="100%">
											<VStack align="flex-start" spacing="0.5" marginLeft="4">
												{menuMain.length
													? menuMain.map((menuMainItem) => (
														<Fragment key={menuMainItem.secure_id}>
															{menuMainItem.menusChildren.length ? (
																<ButtonMenuModal
																	mode="mobile"
																	linkHref={menuMainItem.url}
																	color="white"
																	text={menuMainItem.title}
																	menusChildren={menuMainItem.menusChildren}
																/>
															) : (
																<ButtonMenu
																	linkHref={menuMainItem.url}
																	color="white"
																	text={menuMainItem.title}
																/>
															)}
														</Fragment>
													))
													: undefined}
											</VStack>
										</Flex>
										<HStack spacing={2}>
											{/* {socialMedias?.map((socialMedia) => (
												<LinkIconImageOutline
													key={socialMedia.secure_id}
													isExternal
													linkHref={socialMedia.link}
													iconSrc={socialMedia.icon.url}
													altIcon={
														socialMedia.icon.alt_text ?? socialMedia.name
													}
												/>
											))} */}
											{/* <LinkIconOutline
												icon={FaFacebookF}
												isExternal
												linkHref="https://web.facebook.com/samaisecossistemadevarejo"
											/>
											<LinkIconOutline
												icon={FaInstagram}
												isExternal
												linkHref="https://www.instagram.com/samaisvarejo/"
											/>
											<LinkIconOutline
												icon={FaYoutube}
												isExternal
												linkHref="https://www.youtube.com/channel/UCkzSk0VOjiQ5I6dcqhsHdqg"
											/>
											<LinkIconOutline
												icon={FaLinkedinIn}
												linkHref="https://www.linkedin.com/company/sa-varejo/"
												isExternal
											/> */}
										</HStack>
									</VStack>
								</DrawerBody>
							</DrawerContent>
						</Drawer>
					</VStack>
				</>
			)}
			{isWideVersion && (
				<>
					<HStack
						w="100%"
						h="3.125rem"
						minH="3.125rem"
						bg="backgroundSubHeader"
						px={5}
						justifyContent="space-between"
					>
						{news && news[bannerNumber] && (
							<HStack spacing={4}>
								<Flex
									rounded="14"
									bg="#de0045"
									maxH="1.875rem"
									px={3}
									py={2}
									justifyContent="center"
									align="center"
								>
									<Text color="#FFF" fontWeight="bold" fontSize="sm">
										ÚLTIMAS NOTÍCIAS
									</Text>
								</Flex>
								<Text
									as={Link}
									href={FormatPortalLinks.formatPostLink({ post: news[bannerNumber] })}
									color="#FFF"
									fontSize="sm"
								>
									{news[bannerNumber].title}
								</Text>
							</HStack>
						)}
						<HStack>
							{(currentTemperature && currentTemperature > 0) && (
								<>
									<Icon as={RiSunCloudyFill} fontSize="lg" color="#fff" />
									<Text color="#FFF" fontSize="sm" borderRight="2px" pr="2">
										{currentTemperature} °C
									</Text>
								</>
							)}

							<Icon as={FaRegClock} fontSize="lg" color="#fff" />
							<Text color="#FFF" fontSize="sm">
								{format(new Date(), "dd/MM/yyyy")}
							</Text>
						</HStack>
					</HStack>
					<Grid
						w="100%"
						h="100%"
						px="5"
						gridTemplateColumns={{ xl: "400px 1fr 400px", '2xl': "440px 1fr 440px" }}
					>
						<Flex
							align="center"
						>
							<ButtonIconOutline
								linkHref="#"
								text="NEWSLETTER"
								onClick={handleOpenNewsletterModal}
							/>
						</Flex>
						<Link
							href="/"
							style={{
								alignContent: 'center'
							}}
						>
							<h1>
								{titlePage && (
									<title>{titlePage}</title>
								)}
								<ImageNextjs
									src={customLogo ? customLogo.url : "/logoSAplus.png"}
									alt={customLogo?.alt_text ?? "SA+ Ecossistema de Varejo"}
									height={110}
									width={400}
									sizes="(max-width: 170px) 100vw, (max-width: 400px) 50vw, 33vw"
									style={{
										maxHeight: '110px',
										minWidth: '100%',
										width: '100%',
										objectFit: 'contain',
									}}
								/>
							</h1>
						</Link>
						<HStack
							spacing={2}
							justify="flex-end"
						>
							<Box
								// w={{ sm: '120px', xl: '180px', '2xl': "250px" }}
								paddingLeft={2}
							>
								<InputIcon
									isHome
									name="search"
									placeholder="Encontre na SA+"
								/>
							</Box>
							<Divider
								orientation='vertical'
								h="12"
								borderLeft="2px"
								color="white"
							/>
							<Popover
								returnFocusOnClose={false}
								isOpen={accountPopOver}
								onClose={onCloseAccountPopoOver}
								placement="bottom"
								closeOnBlur={false}
								autoFocus
								onOpen={onToggle}
							>
								<PopoverTrigger>
									<Button variant="unstyled">
										<HStack mr={{ sm: 2, '2xl': 10 }} minW="180px">
											<BsPersonCircle size={35} color="#fff" />
											<VStack gap={0} align="flex-start" minW={asPath === '/' ? { sm: "100px", '2xl': "140px" } : undefined}>
												<Text color="#fff" lineHeight={4}>
													Olá,{" "}
													{!!user?.name
														? user.name.split(" ")[0]
														: "faça seu login"}
												</Text>
												<Flex align="center">
													<Text
														maxW="140px"
														color="#fff"
														fontWeight="600"
														mr={2}
														lineHeight={4}
													>
														{!!user?.roles && user.name
															? "Acesse sua conta"
															: "ou cadastre-se"}
													</Text>
													<IoIosArrowDown size={12} color="#fff" />
												</Flex>
											</VStack>
										</HStack>
									</Button>
								</PopoverTrigger>
								<PopoverContent
									padding={3}
									maxW={250}
								>
									<PopoverArrow />
									<PopoverBody>
										<VStack gap={4}>
											<Text color="#969696" lineHeight="14px">
												Para ter uma experiência personalizada, acesse sua
												conta.
											</Text>
											<VStack w="100%">
												<Button
													w="100%"
													colorScheme="switchActive"
													_hover={{ background: "#4f0d28" }}
													onClick={() =>
														push(isAuthenticated ? "/profile" : "/signin")
													}
												>
													{isAuthenticated ? "Conta" : "Entrar"}
												</Button>
												{!isAuthenticated && (
													<Button
														w="100%"
														variant="outline"
														color="#e21350"
														border="2px solid #e21350"
														_hover={{
															color: "#4f0d28",
															borderColor: "#4f0d28",
														}}
														onClick={() => push("/signup")}
													>
														Cadastrar
													</Button>
												)}
											</VStack>
										</VStack>
									</PopoverBody>
								</PopoverContent>
							</Popover>

							{/* {socialMedias?.map((socialMedia) => (
								<LinkIconImageOutline
									key={socialMedia.secure_id}
									isExternal
									linkHref={socialMedia.link}
									iconSrc={socialMedia.icon.url}
									altIcon={socialMedia.icon.alt_text ?? socialMedia.name}
								/>
							))} */}
						</HStack>
					</Grid>
					<Box
						w="100%"
						h="8px"
						bg={customBackgroud ? customBackgroud : "#de1c47"}
						filter="brightness(0.5)"
					/>
					{menuMain.length ? (
						<Grid
							w="100%"
							px="5"
							gap="3"
							maxW="1366px"
							overflow="hidden"
							bg={customBackgroud ? customBackgroud : "backgroundHeader"}
							h="100%"
							minH="auto"
							templateColumns={
								isLargerThan1355
									? "repeat(5, 1fr)"
									: "repeat(4, 1fr)"
							}
						>
							{menuMain.map((menuMainItem) => (
								<ButtonMenu
									color="white"
									shouldMatchExactHref
									icon={menuMainItem.icon}
									text={menuMainItem.title}
									linkHref={menuMainItem.url}
									key={menuMainItem.secure_id}
									backgroundActive={customBackgroud}
									customBorderColor={menuMainItem.arm?.color_header}
								/>
							))}
						</Grid>
					) : undefined}
					{(menuMain.length && submenuVisibility === 'visible') ? (
						<Flex bg="#EDF2F7" w="100%" justifyContent="center">
							<Grid
								// maxW="1326px"
								minH={"3.75rem"}
								px="5"
								bg="#EDF2F7"
								gap={isLargerThan1355 ? 5 : 4}
								alignContent="center"
								justifyItems="center"
								templateColumns={
									isLargerThan1355 ? "repeat(9, auto)" : "repeat(5, 1fr)"
								}
							>
								{subMain.map((subMainItem) => (
									<Fragment key={subMainItem.secure_id}>
										{subMainItem.menusChildren.length ? (
											<ButtonMenuSubMainModal
												mode="desktop"
												linkHref={subMainItem.url}
												icon={subMainItem.icon}
												text={subMainItem.title}
												menusChildren={subMainItem.menusChildren}
											/>
										) : (
											<ButtonMenu
												linkHref={subMainItem.url}
												icon={subMainItem.icon}
												text={subMainItem.title}
												shouldMatchExactHref={subMainItem.url === "/"}
											/>
										)}
									</Fragment>
								))}
							</Grid>
						</Flex>
					) : submenuVisibility === 'backToHome' ? (
						<Flex bg="#EDF2F7" w="100%" justifyContent="center">
							<Button
								size="md"
								rightIcon={
									<Icon
										as={TbArrowBackUp}
										fontWeight="bold"
									/>
								}
								onClick={() => push('/')}
							>
								Retornar ao Portal SA+
							</Button>
						</Flex>
					) : <></>}
				</>
			)}
			{newsletterModal && (
				<ModalNewsLetterPortal
					isOpen={newsletterModal}
					onClose={handleCloeseNewsletterModal}
				/>
			)}
		</Flex >
	);
};
