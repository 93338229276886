import { isBefore } from "date-fns"
import { PostHomeProps } from "../Types/Home"

type PostLinkProps = {
	post: Pick<PostHomeProps, "publish_at" | "slug">
	arm?: string
}

export default {
	formatPostLink({ post, arm = undefined }: PostLinkProps) {
		if (post.publish_at && !arm) {
			const datePost = new Date(post.publish_at)
			const dateCompare = new Date('2024-06-04')

			if (isBefore(datePost, dateCompare)) {
				return `/detalhe/reportagens/${post.slug}`
			}
		}

		return `/${arm ? arm : "publicacoes"}/${post.slug}`
	},

	validatePostLinkIsBefore({ post }: PostLinkProps) {
		if (post.publish_at) {
			const datePost = new Date(post.publish_at)
			const dateCompare = new Date('2024-06-04')

			return isBefore(datePost, dateCompare)
		}

		return false
	}
}
